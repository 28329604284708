@keyframes underline {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.grey-underlined-text {
    position: relative;
    display: inline;
}

.grey-underlined-text::after {
    content: "";
    display: block;

    position: absolute;
    bottom: 0;
    width: 0;
    height: 50%;
    background: black;
    opacity: 20%;
    transition: width 200ms;
}

.grey-underlined-text:not(.activate-hover)::after{
    animation: 200ms underline linear 200ms;
    animation-fill-mode: forwards;
}

.grey-underlined-text.activate-hover:hover::after {
    width: 100%;
}