@supports (scrollbar-gutter: stable) {
    .project-overview {
        scrollbar-gutter: stable;
        overflow-y: auto;

        width: 100vw;
        height: 100vh;
    }
}

.title-image .loading-image {
    margin: 0 auto;
    margin-top: 5%;
    width: 95%;
    max-width: 70rem;
    max-height: 60vh;
    aspect-ratio: 16 / 9;
    box-shadow: 0 0 20px 10px #00000020;
    object-fit: contain;
}

@supports not (aspect-ratio: 16 / 9) {
    .title-image .loading-image {
        height: 60vh;
    }
}

.title-image {
    display: block;
    margin: 0 auto;
    max-width: 70rem;
    text-decoration: none;
}

.click-info {
    font-family: 'Courier New', monospace;
    display: block;
    font-style: italic;
    margin: 1em 0;
    text-align: center;
    width: 100%;
    white-space: normal;
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    } 100% {
        transform: rotate(360deg);
    }
}

.loading-circle-wrapper {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.loading-circle {
    margin-top: 1em;
    border: 0.25rem solid black;
    border-bottom: 0.2rem solid transparent;
    border-radius: 5rem;
    width: 5rem;
    height: 5rem;
    animation: spinning 2s infinite linear;
}

.link {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 1500px) {
    .link-to-repo {
        position: fixed;
        bottom: 3rem;
        right: 3rem;
    }   
}