@keyframes fade-out {
    0% {
        opacity: 1;
    } 100% {
        opacity: 0;
    }
}

body, html {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

.black-to-white {
    z-index: 100;
    position: fixed;
    line-height: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: black;

    animation: fade-out 1s;
    animation-fill-mode: forwards;
}