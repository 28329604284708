@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');
:root {
    --border-thickness: 0.25rem;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.all-projects li:hover .grey-underlined-text.activate-hover::after {
    width: 100%;
}

.all-projects li:hover .grey-underlined-text::after {
    transition-duration: 280ms;
}

.all-projects {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.all-projects li {
    display: block;
    border: solid 0.25rem black; /* placeholder for animated border */
    width: 90%;
    max-width: 50rem;
    height: 25rem;
}

.all-projects li a {
    text-decoration: none;
    color: black;
    width: 100%;
    height: 100%;
}

.project-container {
    width: 100%;
    height: 100%;
    position: relative;

    box-shadow: 5px 5px 10px #00000020;
}

.border {
    background: #d3d3d3;
    position: absolute;
    z-index: 1;
}

.border.top {
    width: 0;
    height: var(--border-thickness);
    top: calc(-1 * var(--border-thickness));
    left: 0;
    transition: 70ms width cubic-bezier(.99,0,1,1) 170ms;
}

.border.right {
    top: 0;
    right: calc(-1 * var(--border-thickness));
    width: var(--border-thickness);
    height: 0;
    transition: 70ms height cubic-bezier(.99,0,1,1) 120ms;
}

.border.bottom {
    right: 0;
    bottom: calc(-1 * var(--border-thickness));
    width: 0;
    height: var(--border-thickness);
    transition: 70ms width cubic-bezier(.99,0,1,1) 70ms;
}

.border.left {
    left: calc(-1 * var(--border-thickness));
    bottom: 0;
    width: var(--border-thickness);
    height: 0;
    transition: 70ms height cubic-bezier(.99,0,1,1) 0ms;
}

.project-container:hover .border.top {
    width: calc(100% + var(--border-thickness));
    transition: 70ms width cubic-bezier(.99,0,1,1) 0ms;
}

.project-container:hover .border.right {
    height: calc(100% + var(--border-thickness));
    transition: 70ms height cubic-bezier(.99,0,1,1) 70ms;
}

.project-container:hover .border.bottom {
    width: calc(100% + var(--border-thickness));
    transition: 70ms width cubic-bezier(.99,0,1,1) 120ms;
}

.project-container:hover .border.left {
    height: calc(100% + var(--border-thickness));
    transition: 70ms height cubic-bezier(.99,0,1,1) 170ms;
}

.project-container .title-description-wrapper {
    margin: 0 1%;
    width: 98%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.project-container .image {
    width: 100%;
    height: 70%;
    object-position: top;
}

.project-container .image::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 50px 5px #000000;
}

.project-container .title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.project-container .description {
    font-family: 'Montserrat', sans-serif;
}