@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');

.link-design {
    font-family: 'Cutive Mono', monospace; /*TODO maybe default font courier instead of cutive mono? */
    font-size: x-large;
    font-weight: bold;
    color: black;
    text-decoration: none;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* remove grey selection in safari */
}

.link-design img {
    position: absolute;
    color: transparent; /* prevent alt text from showing up */
    top: -0.25em;
    right: -1em;
}