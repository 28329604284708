.error {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error p {
    font-family: Montserrat, sans-serif;
    text-align: center;
}